import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import React from "react";

function App() {
  const [account, setAccount] = useState<null | string[]>(null);
  const [balance, setBalance] = useState<null | string>(null);

  useEffect(() => {
    (async () => {
      // A Web3Provider wraps a standard Web3 provider, which is
      // what MetaMask injects as window.ethereum into each page
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // MetaMask requires requesting permission to connect users accounts
      await provider.send("eth_requestAccounts", []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();

      const accounts = await provider.listAccounts();
      const balance = await signer.getBalance();

      const tokenMerger = new ethers.Contract(
        "0x0a607f69a9c09C9f6e1ED16bd94042dB72D746b6",
        [
          {
            inputs: [],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
              },
              {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
              },
            ],
            name: "AdminChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "beacon",
                type: "address",
              },
            ],
            name: "BeaconUpgraded",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "blocksToWait",
                type: "uint256",
              },
            ],
            name: "BlocksToWaitChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "commissionPercentage",
                type: "uint256",
              },
            ],
            name: "CommissionChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract IERC20",
                name: "token",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                indexed: true,
                internalType: "address",
                name: "receiver",
                type: "address",
              },
            ],
            name: "CommissionWithdrew",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
            ],
            name: "ConversionProtocolAdded",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
            ],
            name: "ConversionProtocolRemoved",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
              },
              {
                indexed: false,
                internalType: "address",
                name: "receiver",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "amountConverted",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "commission",
                type: "uint256",
              },
            ],
            name: "ConversionToCanonical",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
              },
              {
                indexed: false,
                internalType: "address",
                name: "receiver",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "amountConverted",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "commission",
                type: "uint256",
              },
            ],
            name: "ConversionToFragmented",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: false,
                internalType: "bool",
                name: "allowed",
                type: "bool",
              },
            ],
            name: "ConvertingToCanonicalChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                indexed: false,
                internalType: "bool",
                name: "allowed",
                type: "bool",
              },
            ],
            name: "ConvertingToFragmentedChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
              },
            ],
            name: "Initialized",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
              },
            ],
            name: "Upgraded",
            type: "event",
          },
          {
            inputs: [],
            name: "COMMISSION_DECIMALS",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
            ],
            name: "addFragmentedToken",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "blocksToWait",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "calculateCommission",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            name: "canonicalTokens",
            outputs: [
              {
                internalType: "contract CanonicalToken",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "commissionPercentage",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract IERC20",
                name: "",
                type: "address",
              },
            ],
            name: "commissionTreasury",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "",
                type: "address",
              },
            ],
            name: "conversionProtocols",
            outputs: [
              {
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
              {
                internalType: "bool",
                name: "convToCanonicalAllowed",
                type: "bool",
              },
              {
                internalType: "bool",
                name: "convToFragmentedAllowed",
                type: "bool",
              },
              {
                internalType: "uint256",
                name: "activeAtBlock",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "receiver",
                type: "address",
              },
            ],
            name: "convertToCanonical",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "receiver",
                type: "address",
              },
            ],
            name: "convertToFragmented",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract CanonicalToken",
                name: "",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            name: "fragmentedTokensOfCanonical",
            outputs: [
              {
                internalType: "contract FragmentedToken",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getAdmin",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getAllCanonicalTokens",
            outputs: [
              {
                internalType: "contract CanonicalToken[]",
                name: "",
                type: "address[]",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getAllFragmentedTokens",
            outputs: [
              {
                internalType: "contract FragmentedToken[]",
                name: "",
                type: "address[]",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getAllTreasuryTokens",
            outputs: [
              {
                internalType: "contract IERC20[]",
                name: "",
                type: "address[]",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getImplementation",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_blocksToWait",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_commissionPercentage",
                type: "uint256",
              },
            ],
            name: "initialize",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract CanonicalToken",
                name: "canonicalToken",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "mintCanonicalToken",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "numberOfFragmentedTokens",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
            ],
            name: "removeFragmentedToken",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_blocksToWait",
                type: "uint256",
              },
            ],
            name: "setAddingDelay",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "newCommissionPercentage",
                type: "uint256",
              },
            ],
            name: "setCommission",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                internalType: "bool",
                name: "allowed",
                type: "bool",
              },
            ],
            name: "setConvertingToCanonicalState",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract FragmentedToken",
                name: "fragmentedToken",
                type: "address",
              },
              {
                internalType: "bool",
                name: "allowed",
                type: "bool",
              },
            ],
            name: "setConvertingToFragmentedState",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newAdmin",
                type: "address",
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            name: "treasuryTokens",
            outputs: [
              {
                internalType: "contract IERC20",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newImplementation",
                type: "address",
              },
            ],
            name: "upgradeTo",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "contract IERC20",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "receiver",
                type: "address",
              },
            ],
            name: "withdrawCommission",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ],
        provider
      ) as any;

      const canonicalTokens = await tokenMerger.getAllCanonicalTokens();

      const fragmentedTokens = await tokenMerger.getAllFragmentedTokens();
      const conversionPairs = await Promise.all(
        fragmentedTokens.map(async (fragmentedToken: any) => {
          const { canonicalToken } = await tokenMerger.conversionProtocols(
            fragmentedToken
          );

          return {
            fragmentedToken,
            canonicalToken,
          };
        })
      );

      console.log(tokenMerger);
      console.log(canonicalTokens);
      console.log(fragmentedTokens);
      console.log(conversionPairs);

      setAccount(accounts);
      setBalance(ethers.utils.formatEther(balance));
    })();
  }, []);

  const isConnected = account && account.length;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {JSON.stringify(process.env)}
        {!!account && account.map((acc, index) => <p key={index}>{acc}</p>)}
        {!!balance && <p>Balance: {balance}</p>}
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Connection status:
          <span
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              backgroundColor: isConnected ? "green" : "red",
              display: "inline-block",
              marginLeft: "8px",
            }}
          />
        </p>
      </header>
    </div>
  );
}

export default App;
